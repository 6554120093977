.red {
    color: #a71a1a !important;
}

.text-center {
    text-align: center !important;
}

.container {
    padding: 0 !important;
}

.light-background {
    background-color: #f8f8f8 !important;
}

.color-blue {
    color: #0e69de !important;
}

.color-green {
    color: #14d26e !important;
}

.container .content .content-box {
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #d9dce1;
    border-radius: 5px;
}

.container .content .content-box-tight {
    padding: 0 20px !important;
}

.container .content .content-box-alert {
    padding: 2px 20px !important;
    background-color: #ffe6e5 !important;
    position: relative;
}

.container .content .content-box-alert .content-box-alert-close {
    position: absolute;
    top: -2px;
    right: 5px;
    cursor: pointer;
    font-size: 15px;
}

.container .content ul.content-navigation {
    display: block;
    list-style-type: none;
    height: 37px;
    border-bottom: 1px solid #e0e0e0;
    padding: 0;
    margin: 0 0 20px 0;
}

.container .content ul.content-navigation li span,
.container .content ul.content-navigation li a {
    display: block;
    float: left;
    padding: 0 0 14px 0;
    margin: 0 20px 0 0;
    color: #8b969a;
    text-align: center;
    cursor: pointer;
}

.container .content ul.content-navigation li span.active,
.container .content ul.content-navigation li a.active,
.container .content ul.content-navigation li span:focus,
.container .content ul.content-navigation li a:focus,
.container .content ul.content-navigation li span:hover,
.container .content ul.content-navigation li a:hover {
    text-decoration: none;
    color: #15d26e;
    border-bottom: 3px solid #15d26e;
}

.container .content form {
    border: none;
    background-color: #fff;
}

.container .content form .form-row {
    display: block;
    height: 50px;
    clear: both;
}

.container .content form .password-recovery-link {
    height: auto;
}

.container .content form .password-recovery-link a {
}

.container .content form label {
    display: block;
    float: left;
    font-size: 14px;
    width: 100px;
    padding: 15px 10px 0 0;
    text-align: right;
}

.container .content .content-form label {
    width: 170px;
    text-align: left;
}

.container .content form .form-legend {
    display: block;
    font-size: 14px;
    color: #9ea6a8;
    padding: 5px 0;
    text-align: right;
    border-bottom: 1px solid #e0e0e0;
}

.container .content form span {
    display: block;
    float: left;
    font-size: 14px;
    padding: 15px 0 0 10px;
    text-align: right;
    color: #a71a1a;
}

.container .content form mark {
    display: block;
    float: left;
    font-size: 14px;
    margin: 14px 0 0 10px;
}

.container .content form span.info {
    color: #9ea6a8;
}

.container .content form select,
.container .content form input {
    display: block;
    float: left;
    background-color: #fff;
    border-radius: 5px;
}

.container .content form button {
    display: block;
    float: left;
    margin: 5px 0 0 5px;
    border-radius: 5px;
}

.container .content form .password-recovery-link {
    color: #8b969a;
}

.container .content form .password-recovery-link:hover {
    color: #49565c;
}

.container .content .content-form button {
    margin-left: 5px;
}

.container .content h2 {
    font-size: 20px;
    color: #1b3448;
    font-weight: normal;
}

.container .content h3 {
    font-size: 18px;
    color: #1b3448;
}

.container .content p {
    font-size: 14px;
    color: #1b3448;
}

.container .content ol li,
.container .content ul li {
    font-size: 14px;
    color: #1b3448;
}

.container .content a {
    font-size: 14px;
    color: #0f68de;
}

.container .content .spinner {
    margin-left: 10px;
}

.container-table .row {
    color: #1b3544;
    padding: 20px 0;
    border-bottom: 1px solid #e0e0e0;
}

.container-table .row p {
    padding: 0;
    margin: 0;
}

.container-table .row .course-indicator {
    display: block;
    float: left;
    font-size: 30px;
    line-height: 1px;
    padding: 10px 10px 15px 0;
}

.container-table .container-table-disclaimer {
    padding: 15px 0 !important;
    font-size: 12px !important;
    color: #8d8d8d !important;
}

.container-table div input {
    display: block;
    float: right;
    padding: 4px;
    font-size: 13px;
    margin: 3px 10px 0 0;
}

.container-table div button {
    border-radius: 5px;
    display: block;
    float: right;
    margin: 0 20px 0 0;
}