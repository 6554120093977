.content .display-center {
    display: block;
    margin: 10px auto;
    padding: 5px;
    border: 1px solid #293d56;
}

.course-content h2 {
    padding: 0 0 0 5px;
    margin: 0;
}

.course-content .container-table .row h3 {
    padding: 0 0 0 10px;
    margin: 0;
}

.course-content .container-table .row p {
    padding: 5px 0 0 10px;
    margin: 0;
}

.course-content .container-table .row h3 a {
    font-size: 18px !important;
}

.content ul.course-advertisement-links {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
}