.container .certificates-list {
    list-style-type: none;
    padding: 0;
    margin: 20px 0 40px 10px;
    border-top: 1px solid #e1dfe0;
}

.container .certificates-list li {
    display: block;
    font-size: 14px;
    color: #6e808c;
    padding: 5px 10px;
    border-bottom: 1px solid #e1dfe0;
}


.container .certificates-list .certificate-course {
    display: block;
    float: left;
}

.container .certificates-list h2 {
    margin-left: 0;
    margin-bottom: 5px;
}

.container .certificates-list .certificate-action button {
    border-radius: 5px;
    margin-left: 0;
    margin-right: 0;
}

.container .certificates-list li:nth-child(even) {
    background-color: #fbfcfe;
}