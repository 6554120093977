.container .content .communicate,
.container .content form .form-row .communicate {
    margin: 0;
    padding: 5px 0 5px 5px;
    font-weight: bold;
    color: #30b36d;
    border-left: 10px solid #30b36d;
}

.container .content .communicate:hover,
.container .content form .form-row .communicate:hover {
    cursor: not-allowed;
}

.container .content .error,
.container .content form .form-row .error {
    color: #b71c1c;
    border-left: 10px solid #b71c1c;
}

.container .test-form .test-row {
    margin-bottom: 40px;
}