.container .news-bar {
    background-color: #fff;
    border-left: 1px solid #d9dce1;
    margin-left: -1px;
    padding: 0 0 50px 0;
}

.container .news-bar h2 {
    padding: 28px 0 28px 2px;
    margin: 0 20px;
    color: #4b5964;
    font-weight: normal;
    font-size: 20px;
    border-bottom: 1px solid #e0e0e0;
}

.container .news-bar .news-entry {
    margin: 0 20px;
    border-bottom: 1px solid #e0e0e0;
}

.container .news-bar .news-entry h3 {
    font-size: 16px;
    padding: 20px 0;
    margin: 0;
    color: #1e3044;
}

.container .news-bar .news-entry small {
    color: #0f68de;
    padding: 0 0 5px 0;
    margin: 0;
    font-weight: bold;
}

.container .news-bar .news-entry p {
    font-size: 14px;
    margin: 0 0 15px 0;
    padding: 15px 0 0 0;
    color: #1e2f49;
}