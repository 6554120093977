.container .help-content ul {
    margin-bottom: 50px;
}
.container .help-content p {
    margin-left: 30px
}

.container .help-content a.help-back {
    display: block;
    margin: 0 0 40px 30px;
}