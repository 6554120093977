.container .content .courses-list p {
    color: #6e808e;
}

.container .content .courses-list ul {
    list-style-type: none;
    padding: 0;
    margin: 20px 0 40px 10px;
    border-top: 1px solid #e1dfe0;
}

.container .content .courses-list li {
    display: block;
    font-size: 14px;
    color: #6e808c;
    padding: 5px 10px;
    border-bottom: 1px solid #e1dfe0;
}

.container .content .courses-list li:nth-child(even) {
    background-color: #fbfcfe;
}

.container .content .courses-list a {
    color: #32b46e;
}