.container .row .adv-bar,
.container .row .navigation-bar {
    background-color: #203047;
}

.container .row .navigation-bar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.container .row .navigation-bar ul a {
    display: block;
    height: 49px;
    border-bottom: 1px solid #293d56;
    color: #fefeff;
    padding: 30px 0 0 50px;
}

.container .row .navigation-bar a.active,
.container .row .navigation-bar a:focus,
.container .row .navigation-bar a:hover {
    text-decoration: none;
    color: #15d26e;
}

.container .row .navigation-bar select {
    width: 95%;
}