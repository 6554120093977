.row .logo {
    background-color: #111b27;
    border-bottom: 1px solid #111b27;
    height: 79px;
}

.row .logo img {
    display: block;
    margin: 15px auto;
    max-width: none !important;
}

.row .breadcrumb {
    height: 79px;
    background-color: #ffffff;
    border-bottom: 1px solid #d9dde0;
}

.row .breadcrumb h1 {
    display: block;
    float: left;
    padding: 14px 0 0 0;
    color: #49565c;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 22px;
    font-family: Helvetica, Arial, sans-serif !important;
}

.row .breadcrumb span {
    font-size: 18px;
    float: left;
    margin: 29px 0 0 20px;
}

.row .login-status {
    height: 79px;
    background-color: #ffffff;
    border-bottom: 1px solid #d9dde0;
}

.row .login-status span {
    display: block;
    font-weight: bold;
    float: right;
    margin: 15px 15px 0 0;
    font-size: 12px;
    color: #a6adb7;
    text-align: right;
    line-height: 15px;
}

.row .login-status span.unlogged {
    margin-top: 30px;
}

.row .login-status b {
    display: block;
    height: 25px;
    margin: 17px 20px 0 0;
    padding: 12px 0 0 32px;
    border-left: 1px solid #d9dde0;
    float: right;
    color: #a5acb6;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    background: url('/assets/images/logout.png') no-repeat 10px 11px;
}

.row .login-status b:hover {
    text-decoration: none;
    color: #a71a1a;
}